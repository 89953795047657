<template>
<div class='howitworks-component component'>

    <div class="section-title">How it works</div>
    <div class="howitworks-set row">
        <div class="howitworks__item col">
            <div class="howitworks__step-icon">
                <div class="icon-image item_1"></div>
            </div>
            <div class="howitworks__step-name">Pre-service inspection</div>
            <div class="howitworks__step-desc">Under that sample collection & lab test if required</div>
        </div>
        <div class="howitworks__item col">
            <div class="howitworks__step-icon">
                <div class="icon-image item_2"></div>
            </div>
            <div class="howitworks__step-name">Analyse and investigate Treatment solutions</div>
            <div class="howitworks__step-desc">Investigate best treatment solutions – reuse, recycle if not economical & less environmental impact disposal options</div>
        </div>
        <div class="howitworks__item col">
            <div class="howitworks__step-icon">
                <div class="icon-image item_3"></div>
            </div>
            <div class="howitworks__step-name">Collection and disposal process</div>
            <div class="howitworks__step-desc">Organising Waste Authority Permits, control waste License vehicles and transport to relevant disposal facilities according to fulfil WA legislation requirement: witnessing Disposal process</div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'howitworks-component',
    data() {
        return {}
    },
    props: {
        msg: String
    },
    components: {

    }
}
</script>

<style lang="scss">
@import "../scss/common.scss";

.howitworks-component {
    .howitworks-set {
        // display: flex;
        align-items: flex-start;
        // margin-top: 20px;

        .howitworks__item {
            // width: 33.33%;
            text-align: center;

            @media only screen and (max-width: $break-medium) {
                margin-bottom: 35px;
            }

            .howitworks__step-icon {
                .icon-image {
                    width: 200px;
                    height: 200px;
                    background-size: cover;
                    margin: auto;

                    &.item_1 {
                        background-image: url('../assets/images/how-1.png');
                    }

                    &.item_2 {
                        background-image: url('../assets/images/how-2.png');
                    }

                    &.item_3 {
                        background-image: url('../assets/images/how-3.png');
                    }
                }
            }

            .howitworks__step-name {
                margin-top: 10px;
                font-size: $font-size-xxlarge;
                font-weight: bold;

            }

            .howitworks__step-desc {
                font-size: $font-size-medium;
                color: $main-gray;
            }
        }
    }
}
</style>
