<template>
<div class="header-section">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                <a class="navbar-brand" href="#">
                    <div class="logo"><img src="../assets/logo.png" alt="">
                        <div class="company-name">EDEN Waste Solutions</div>
                    </div>
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="#">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"  href="#sec-about" >About Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"  href="#sec-how" >How it works</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"  href="#sec-services" >Services</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"   href="#sec-contact" >Contact Us</a>
                        </li>
                    </ul>
                    <div class="d-flex call-info">
                        <ul>
                            <li class="nav-item call">
                                <div class="call-now">
                                    <div class="icon"></div>
                                    <div class="number">CALL NOW : 04 30051443</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>
</template>

<script>
export default {
    name: 'header-component'
}
</script>

<style lang="scss">
@import "../scss/common.scss";

.header-section {
    .navbar {
        background-color: #ffffff !important;

        .navbar-brand {
            min-width: 218px;

            .logo {
                width: 30px;
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: $font-size-large;
                font-weight: bold;
                color: $main-green;

                img {
                    width: 100%;
                }
            }
        }

        .call-info>ul {
            margin: 0;
            @media only screen and (max-width: $break-large){
                 padding-left:0px;
            }
        }

        .nav-item {
            font-size: $font-size-medium;

            .nav-link {
                font-weight: 600;
    display: block;
            }

            &.call {
                list-style: none;
            }

            .call-now {
                display: flex;
                align-items: center;
                gap: 6px;

                .icon {
                    width: 32px;
                    height: 32px;
                    background-image: url('../assets/call-now.png');
                }

                .number {
                    font-size: $font-size-large;
                    font-family: 'Milliard SemiBold';
                    font-weight: bolder;
                }

            }

        }
    }
}
</style>
