<template>
  <main-component class="main-app" />
</template>

<script>
import MainComponent from './components/main-component.vue'

export default {
    name: 'App',
    components: {
        MainComponent
    }
}
</script>

<style lang="scss">
@import "./scss/common.scss";
@import "./scss/fonts.scss";

.main-app {
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $main-green;
}
</style>
