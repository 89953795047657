<template>
<div class="main-component">

    <header-component id="home"></header-component>
    <slider-component class="section"></slider-component>
    <about-component id="sec-about" class="container section"></about-component>
    <howitworks-component id="sec-how" class="container section"></howitworks-component>
    <services-component id="sec-services" class="section" @openPopup="openPopup"></services-component>
    <client-component id="sec-clients" class="section"></client-component>
    <footer-component id="sec-contact"></footer-component>
    <transition name="fade-opacity" tag="p">
        <div class="popup" v-if="isPopupModalOpen">
            <transition name="fade-scale-up" tag="p">
                <div class="popup-container" v-if="isPOpupOpen">
                    <div class="popup-close" @click="closePopup()"></div>
                    <div class="popup-header">{{this.popupTitle}}</div>
                    <div class="popup-content"><p v-html="popupMessage"></p></div>
                </div>
            </transition>
        </div>

    </transition>
</div>
</template>

<script>
import headerComponent from "./header-component.vue"
import sliderComponent from "./slider-component.vue"
import aboutComponent from "./about-component.vue"
import howitworksComponent from "./howitworks-component.vue"
import servicesComponent from "./services-component.vue"
import clientComponent from "./clients-component.vue"
import footerComponent from "./footer-component.vue"

export default {
    name: 'MainComponent',
    data() {
        return {
            isPOpupOpen: false,
            isPopupModalOpen: false,
            popupMessage: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            popupTitle: "Liquid Waste"
        }
    },
    props: {
        msg: String
    },
    components: {
        headerComponent,
        sliderComponent,
        howitworksComponent,
        servicesComponent,
        footerComponent,
        clientComponent,
        aboutComponent
    },
    methods: {

        openPopup: function (value) {
            let self = this;
            if (value == 'liquid') {
                this.popupMessage = "Liquid waste generate from manufacturing or servicing industries  identified as control waste. (contaminated).";
                this.popupTitle = "Liquid Waste";
            } else if (value == 'dangerous') {
                this.popupMessage = "Used and outdated chemicals for various industries including mining industry.";
                this.popupTitle = "Dangerous Goods/ Controlled Waste";
            } else if (value == 'general') {
                this.popupMessage = "General waste bins - front lift & skip bins or specialises wheelie bins which is not covered by local councils.";
                this.popupTitle = "General Waste Collection";
            } else if (value == 'destruction') {
                this.popupMessage = '<p>Bulk disposal of various products not suitable for consumers (outdated or manufacturing defects) EWS will provide disposal certificate for accreditation.</p>' +
                    '<p>We serve: Local Councils, Manufacturing industry, Servicing Industry, Small Business, Hospitals, School, Government Departments, Environmental consultants, Builders</p>';
                this.popupTitle = "Product Destruction";
            } else if (value == 'hydrocarbon') {
                this.popupMessage = "Hydro carbon contaminated wash waters,  oily rags, filters & soil from Various contaminated site. environmental consultants monthly Lab samples etc.";
                this.popupTitle = "Hydrocarbon Waste";
            }

            self.isPopupModalOpen = true;
            self.isPOpupOpen = true;
        },
        closePopup: function () {
            let self = this;
            self.isPOpupOpen = false;
            setTimeout(() => {
                self.isPopupModalOpen = false;
            }, 1);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
@import "../scss/common.scss";

.main-component {
    position: relative;

    .popup {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.1);
        top: 0;
        left: 0;
        z-index: 10;
        border-radius: 10px;
        backdrop-filter: blur(6px);

        .popup-container {
            position: absolute;
            z-index: 100;
            // width: calc(100% - 100px);
            width: 60%;
            height: fit-content;
            background-color: #ffffff;
            border-radius: 10px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.226);
            padding: 30px;
            box-sizing: border-box;

            .popup-close {
                width: 28px;
                height: 28px;
                margin-top: 3px;
                margin-right: 2px;
                @include set-close-icon(14px, 2px, #727272);
                position: relative;
                border: 2px solid #727272;
                border-radius: 26px;
                cursor: pointer;
                z-index: 10;
                position: absolute;
                right: 10px;
                top: 8px;

                @include set-btn-click-animation(transparent, #727272, 0.95);

                &.disabled {
                    cursor: default !important;
                    @include remove-btn-click-animation(#ffffff, #ffffff);
                }
            }

            .popup-header {
                font-size: $font-size-xxlarge;
                color: $main-black;
                font-family: $font-family-semibold;
            }

            .popup-content {

                font-size: $font-size-medium;
                color: $main-black;
                font-family: $font-family;
                margin-top: 20px;
            }
        }
    }

    .section {
        padding: 0 0 100px 0;

        @media only screen and (max-width: $break-large) {

            padding: 0 0 60px 0;
        }
    }
}

@include fade-opacity-transition(.2s);
@include fade-scale-up-transition(.1s, 0.95);
</style>
