<template>
<div class='services-component container component'>

    <div class="section-title">Services</div>
    <div class="services-items-set">
        <div class="services-col item-2">
            <div class="services-item" @click="openPopup('liquid')">
                <div class="item liquid">
                    <div class="description">Liquid Waste</div>
                    <div class="overlay"></div>
                </div>
            </div>
            <div class="services-item">
                <div class="item dangerous" @click="openPopup('dangerous')">
                    <div class="description">Dangerous Goods/ Controlled Waste</div>
                    <div class="overlay"></div>
                </div>
            </div>
        </div>
        <div class="services-col  item-3">
            <div class="services-item">
                <div class="item general" @click="openPopup('general')">
                    <div class="description">General Waste Collection</div>
                    <div class="overlay"></div>
                </div>
            </div>
            <div class="services-item">
                <div class="item destruction" @click="openPopup('destruction')">
                    <div class="description">Product Destruction</div>
                    <div class="overlay"></div>
                </div>
            </div>
            <div class="services-item">
                <div class="item hydrocarbon" @click="openPopup('hydrocarbon')">
                    <div class="description">Hydro Carbon waste</div>
                    <div class="overlay"></div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: 'services-component',
    data() {
        return {
       
        }
    },
    props: {
        msg: String
    },
    components: {

    },
    methods: {
        openPopup: function (value) {
            this.$emit('openPopup', value);
        }
    }
}
</script>

<style lang="scss">
@import "../scss/common.scss";

.services-component {
    .services-items-set {
        display: flex;
        position: relative;
        flex-direction: row;
        margin: 0 -6px;

        @media only screen and (max-width: $break-medium) {
            display: initial;
        }

        .services-col {
            position: relative;
            flex-grow: 1;
            flex: 1 0 50%;

            .services-item {
                height: 200px;
                padding: 3px;
                position: relative;

                .item {

                    position: relative;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    cursor: pointer;

                    &.dangerous {
                        background-image: url('../assets/images/service-dangerous.webp');
                        background-size: cover;
                        background-position: center;
                    }

                    &.liquid {
                        background-image: url('../assets/images/service-liquid.webp');
                        background-size: cover;
                        background-position: center;
                    }

                    &.general {
                        background-image: url('../assets/images/service-general.webp');
                        background-size: cover;
                        background-position: center;
                    }

                    &.destruction {
                        background-image: url('../assets/images/service-destructure.webp');
                        background-size: cover;
                        background-position: center;
                    }

                    &.hydrocarbon {
                        background-image: url('../assets/images/service-hydrocarbon.webp');
                        background-size: cover;
                        background-position: center;
                    }

                    .description {
                        position: absolute;
                        width: 100%;
                        height: fit-content;
                        z-index: 1;
                        color: #ffffff;
                        font-size: $font-size-xxlarge;
                        font-family: $font-family-semibold;
                        bottom: 20px;
                        padding: 20px;
                    }

                    .overlay {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        background: linear-gradient(38deg, rgba(5, 63, 32) 0%, rgba(4, 43, 22, 0) 100%);
                        top: 0;
                        z-index: 0;
                    }

                    transition: all .2s ease-in-out;

                    &:hover {
                        z-index: 10;
                        transform: scale(1.1);
                    }
                }

            }

            &.item-2 {
                .services-item {
                    height: 300px;
                    padding: 3px;

                    @media only screen and (max-width: $break-large) {
                        height: 200px;
                    }
                }
            }
        }
    }
}
</style>
