<template>
<div class='footer-comnponent'>
    <div class="getintouch__container container">
        <div class="row">
            <div class="details-content col">
                <div class="section-title">Get in touch</div>
                <div class="description">
                    <p>
                        Eden Waste Solutions Pty Ltd is the one-stop shop for all your waste management needs. We offer environment friendly solutions that meet the highest standards of quality and safety. Contact us today for a consultation!
                    </p>
                </div>
                <div class="contact-points-set">
                    <div class="contact-point">
                        <div class="icon phone"></div>
                        <div class="details">
                            <div class="contact-type">Phone:</div>
                            <div class="contact-details">04 30051443</div>
                            <div class="contact-details">08 94527478</div>
                        </div>
                    </div>
                    <div class="contact-point">
                        <div class="icon email"></div>
                        <div class="details">
                            <div class="contact-type">Email:</div>
                            <div class="contact-details">i.fernando@edenwaste.com.au</div>
                        </div>
                    </div>
                    <div class="contact-point">
                        <div class="icon address"></div>
                        <div class="details">
                            <div class="contact-type">Address:</div>
                            <div class="contact-details">No :29, Osprey way, Thornlie WA 6108</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="social">
                    <div class="social-title">Connect with us</div>
                    <div class="social-icon-set">
                        <div class="icon fb"></div>
                        <div class="icon whatsapp"></div>
                        <div class="icon twitter"></div>
                        <div class="icon youtube"></div>
                    </div>
                </div> -->
            </div>
            <div class="query-content col">
                <div class="query-form">
                    <div class="form-container">
                        <div class="form-title">Make your inquiry</div>
                        <div class="input-section">
                            <form ref="form" @submit.prevent="sendEmail">
                                <div class="input-item">
                                    <div class="input">
                                        <input type="text" class="txt-name" name="name" id="name" v-model="nameValue" placeholder="Your Name..">
                                    </div>
                                    <div class="input">
                                        <input type="text" class="txt-email" name="email" v-model="emailValue" id="email" placeholder="Your Email..">
                                    </div>
                                    <div class="input">
                                        <textarea class="message" name="message" rows="4" id="message" v-model="messageValue" placeholder="Message.."></textarea>
                                    </div>
                                    <div class="message-container" v-if="responseMessage!=''">
                                        <div class="message">{{responseMessage}}</div>
                                    </div>
                                    <div class="submit-container">
                                        <input class="btn-submit" type="submit" value="SEND" v-bind:class="{'disable':isLoading}" :disabled="isLoading">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-base-container">
        <div class="footer-base container">
            <div class="about-summary ">
                <div class="summary-logo">
                    <div class="logo"></div>
                    <div class="company-name">EDEN Waste Solutions</div>
                </div>
                <div class="summary-description">
                    <p>Eden Waste Solutions offers comprehensive waste management and cleaning services that are environmentally friendly and efficient. We strive to provide our clients with solutions that are in line with their specific needs and goals, and work hard to ensure that all of our services are reliable and affordable. </p>
                </div>
            </div>
            <div class="navigation-links">
                <div class="nav-item-set">
                    <div class="nav-item">
                        <a href="#home">Home</a>
                    </div>
                    <div class="nav-item">
                        <a href="#sec-about">About Us</a>
                    </div>
                    <div class="nav-item">
                        <a href="#sec-how">How it works</a>
                    </div>
                    <div class="nav-item">
                        <a href="#sec-services">Services</a>
                    </div>
                    <div class="nav-item">
                        <a href="#sec-contact">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="copyright-content">Copyright © 2022, Eden Waste Australia. Developed by <a href="https://lwddigital.com.au/" target="_blank">LWD Digital</a>, All Rights Reserved</div>
        </div>
    </div>
</div>
</template>

<script>
// const querystring = require("querystring");
import axios from 'axios'
export default {
    name: 'footer-comnponent',
    data() {
        return {
            isLoading: false,
            isSuccess: false,
            responseMessage: "",
            sent: false,
            nameValue: "",
            emailValue: "",
            messageValue: ""
        }
    },
    props: {
        msg: String
    },
    components: {

    },
    methods: {
        sendEmail() {
            let self = this;
            self.isLoading = true;
            // emailjs.sendForm('service_f1s4qh5', 'template_3cj5g56', this.$refs.form, '236kTlwoPbKFTBmNt')
            //     .then((result) => {
            //         self.isLoading = false;
            //         self.isSuccess = true;
            //         self.responseMessage = "Request sent successfully !"
            //         console.log('SUCCESS!', result.text);
            //     }, (error) => {
            //         self.isLoading = false;
            //         self.isSuccess = false;
            //         self.responseMessage = "Failed request !"
            //         console.log('FAILED...', error.text);
            //     });

            // e.preventDefault();
            console.info("Aaaaaaa");
            console.info(self.nameValue);
            console.info(self.emailValue);
            console.info(self.messageValue);
            self.responseMessage = "";
            // let data = {
            //     'param1': 'value1',
            //     'param2': 'value2',
            // }
            // axios.post(
            //     "https://uniclk-com.preview-domain.com/mail.php", {
            //         headers: {
            //             'Content-type': 'application/x-www-form-urlencoded',
            //         },
            //         body: this.name
            //     }
            // )
            // const article = "Vue POST Request Example";
            axios({
                    method: 'post',
                    url: "https://edenwaste.com.au/mail.php",
                    data: {
                        name: self.nameValue,
                        email: self.emailValue,
                        message: self.messageValue
                    }
                })
                .then((result) => {
                    self.isLoading = false;
                    self.isSuccess = true;
                    self.nameValue = "";
                    self.emailValue = "",
                        self.messageValue = ""

                    self.responseMessage = "Request sent successfully !"
                    console.log('SUCCESS!', result.text);
                    setTimeout(() => {

                        self.responseMessage = "";
                        self.isLoading = false;
                        self.isSuccess = false;
                    }, 5000);
                }, (error) => {
                    self.isLoading = false;
                    self.isSuccess = false;
                    self.responseMessage = "Failed request !"
                    console.log('FAILED...', error.text);
                    setTimeout(() => {

                        self.responseMessage = "";
                        self.isLoading = false;
                        self.isSuccess = false;
                    }, 5000);
                });
        }

    }
}
</script>

<style lang="scss" scoped>
@import "../scss/common.scss";

.footer-comnponent {
    .getintouch__container {
        display: flex;

        .details-content {
            text-align: left;

            .title {
                font-size: $font-size-xxlarge;
                color: $main-black;
                font-weight: bold;
            }

            .description {
                font-size: $font-size-medium;
                color: $main-gray;

                p {}
            }

            .contact-points-set {
                display: flex;

                @media only screen and (max-width: $break-medium) {
                    flex-direction: column;
                }

                .contact-point {
                    display: flex;
                    flex-grow: 1;

                    @media only screen and (max-width: $break-medium) {
                        padding: 10px 0;
                    }

                    .icon {

                        flex: 30px 0 0;
                        background-repeat: no-repeat;
                        background-position: center;
                        height: 24px;

                        &.phone {
                            background-image: url('../assets/images/contact-icon-phone.png');
                        }

                        &.email {
                            background-image: url('../assets/images/contact-icon-email.png');
                        }

                        &.address {
                            background-image: url('../assets/images/contact-icon-address.png');
                        }
                    }

                    .details {

                        .contact-type {
                            font-weight: bold;
                            font-size: $font-size-large;
                        }

                        .contact-details {
                            font-size: $font-size-large;
                            color: $main-gray;
                        }
                    }
                }
            }

            .social {
                margin-top: 30px;

                .social-title {
                    font-weight: bold;
                    font-size: $font-size-large;
                }

                .social-icon-set {
                    display: flex;
                    gap: 20px;
                    margin-top: 10px;

                    .icon {
                        width: 30px;
                        height: 30px;
                        // background: #333;
                        cursor: pointer;

                        &.fb {
                            background-image: url('../assets/images/icon-fb.png');
                        }

                        &.whatsapp {
                            background-image: url('../assets/images/icon-whatsapp.png');
                        }

                        &.twitter {
                            background-image: url('../assets/images/icon-twitter.png');
                        }

                        &.youtube {
                            background-image: url('../assets/images/icon-youtube.png');
                        }
                    }
                }
            }
        }

        .query-content {
            width: 500px;
            flex: 0 0 500px;

            @media only screen and (max-width: $break-large) {
                width: 100%;
                flex: 0 0 100%;
                margin-top: 40px;
                margin-bottom: 40px;
            }

            .query-form {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.226);
                background-color: #ffffff;
                border-radius: 10px;
                padding: 60px;

                .form-container {

                    .form-title {
                        font-weight: bold;
                        font-size: $font-size-xlarge;
                        margin-bottom: 20px;
                    }

                    .input-section {

                        .input-item {

                            .input {
                                padding: 10px 0;

                                input {
                                    width: 100%;
                                    font-size: $font-size-medium;
                                    border: 1px solid #d3d3d3;
                                    padding: 10px 10px;
                                    border-radius: 5px;

                                }

                                textarea {
                                    width: 100%;
                                    font-size: $font-size-medium;
                                    border: 1px solid #d3d3d3;
                                    padding: 6px 10px;
                                    border-radius: 5px;

                                }

                                .txt-name {}
                            }

                            .message-container {
                                font-size: $font-size-medium;

                                .message {}
                            }

                            .submit-container {

                                .btn-submit {
                                    background-color: #0e7235;
                                    color: #ffffff;
                                    border: none;
                                    width: 100%;
                                    font-size: $font-size-large;
                                    padding: 10px;
                                    border-radius: 6px;
                                    cursor: pointer;
                                    margin-top: 30px;
                                    transition: all .2s ease;

                                    &:active {
                                        transform: scale(.98);
                                    }

                                    &.disable {
                                        opacity: 0.5;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-base-container {
        min-height: 300px;
        background-color: #022b14;
        margin-top: -216px;
        padding: 60px 0 0 0;

        @media only screen and (max-width: $break-large) {
            margin-top: 0px;
        }

        .footer-base {
            padding-bottom: 20px;

            .about-summary {
                width: 50%;
                flex: 0 0 50%;
                color: #ffffff;
                font-size: $font-size-medium;

                @media only screen and (max-width: $break-large) {
                    width: 100%;
                }

                .summary-logo {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .logo {
                        width: 40px;
                        height: 60px;
                        background-image: url('../assets/logo.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                    }

                    .company-name {
                        color: #ffffff;
                        font-size: $font-size-large;
                        font-weight: bold;
                    }

                }

                .summary-description {
                    margin-top: 20px;

                    p {}
                }
            }

            .navigation-links {

                .nav-item-set {
                    display: flex;
                    padding: 20px 0;
                    gap: 20px;

                    .nav-item {

                        a {
                            text-decoration: none;
                            color: #8ab29c;
                            font-size: $font-size-small;
                            transition: all .2s ease;

                            &:hover {
                                color: #c8e0d3;
                            }
                        }
                    }
                }
            }
        }

        .copyright {

            background-color: #242e28;
            padding: 20px 0;

            .copyright-content {
                font-size: $font-size-xsmall;
                text-align: center;
                color: #cfcfcf;

                a {
                    text-decoration: none;
                    color: #1fb645;
                }
            }
        }
    }
}
</style>
