<template>
<div class='about-component component'>
    <div class="row">
        <div class="col-md-8">
            <div class="about-description-container">
                <div class="section-title">About Us</div>
                <div class="about-description">
                    <p>Eden Waste Solutions Pty Ltd is one-stop shop equipped with environment friendly solutions to deal with waste management, Construction and residential waste removal, Commercial and industrial cleaning services, </p>
                    <p>Day by day the natural resources becoming scarcer, also facing never ending challenges of urbanisation, consumerism, and climate change. This is why our main focus is to devise innovative models that support growth and sustainability.</p>
                    <p>Having more than a decade of experience in the waste and recycling industries, our team of specialists are more than qualified to assist customers in reducing and managing their waste and carry out the Waste management Consultancy , Administration of the necessary documentation in relation to the waste removal.</p>
                    <p>Eden Waste Solutions Pty Ltd aims to provide our clients with the best solution of waste disposal options, whether it is general waste, hazardous waste, or medical waste, Eco resources Management has a solution for your site. From Yanchep to Mandurah; our waste management consultants are able to tailor solutions for each unique site to save on costs, while promoting a sustainable future. They all come with competitive rates without compromising the safety ,reliability and sustainability which are our core values</p>
                    <p>Currently, we are working hard to minimise waste by providing once off and regular collections for waste water, plastic and metal drums, Cardboard Recycling, and building construction waste.</p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="about-banner-container">
                <div class="about-banner-wrapper">
                    <div class="about-image">

                        <div class="about-state">" We <b>Analyse</b>, <b>Recycle</b> If not find <b>the Solution to Treatment</b> and <b>Re-use</b> if <b>not Economical Environmental friendly Disposal</b> option"</div>
                        <div class="overlay">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <br>
            <br>
            <br>
            <div class="about-description-container">
                <div class="section-title">Overview</div>
                <div class="about-description">
                    <p>Eden Waste Solutions Pty Ltd is your one-stop shop for environmentally friendly industrial waste management, construction and residential waste removal, as well as commercial and industrial cleaning services. Our comprehensive approach ensures that we address the waste management needs of various sectors while prioritizing sustainability and safety.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <br>
            <div class="about-description-container">
                <div class="section-title">Our Mission</div>
                <div class="about-description">
                    <p>In a world where natural resources are becoming increasingly scarce, and the challenges of urbanization, consumerism, and climate change continue to grow, Eden Waste Solutions is committed to devising innovative models that support both growth and sustainability. Our goal is to provide top-tier waste disposal solutions that not only meet the immediate needs of our clients but also contribute to a more sustainable future.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <br>
            <div class="about-description-container">
                <div class="section-title">Services</div>
                <div class="about-description">
                <p>With over a decade of experience in the waste and recycling industry, our team of specialists is well-equipped to assist customers in reducing and managing their waste effectively. Our services include:</p>
                <p>Waste Management Consultancy: Tailored advice and strategies to manage and minimize waste for industrial, commercial, and residential clients.</p>
                <p>Waste Removal: Comprehensive services for general waste, hazardous waste, and medical waste, ensuring compliance with all regulatory requirements.</p>
                <p>Cleaning Services: Expert commercial and industrial cleaning services, designed to maintain hygiene and safety in various environments.</p>
                <p>Documentation Administration: We manage all necessary documentation related to waste removal, ensuring full compliance with WA government legislative requirements imposed by the Department of Environment.</p>
                </div>
            </div>
        </div>
    </div>

    
    <div class="row">
        <div class="col-md-12">
            <br>
            <div class="about-description-container">
                <div class="section-title">Coverage</div>
                <div class="about-description">
                <p>From Yanchep to Mandurah, our waste management consultants provide tailored solutions for each unique site, helping our clients save on costs while promoting a sustainable future. Our services are competitively priced, ensuring safety, reliability, and sustainability—our core values—are never compromised.</p>
                 </div>
            </div>
        </div>
    </div>

        
    <div class="row">
        <div class="col-md-12">
            <br>
            <div class="about-description-container">
                <div class="section-title">Innovation and Growth</div>
                <div class="about-description">
                <p>In our ongoing effort to minimize waste, we offer both one-off and regular collection services for wastewater, industrial hazardous waste, plastic and metal drums, and construction waste. Additionally, our recent joint venture with Westref Enviro – Kewdale has enabled us to introduce even more reliable and expansive services to our clients.</p>
                 </div>
            </div>
        </div>
    </div>

        
    <div class="row">
        <div class="col-md-12">
            <br>
            <div class="about-description-container">
                <div class="section-title">History</div>
                <div class="about-description">
                <p>Founded in 2014 as Eco Resources Management, Eden Waste Solutions began as a small, family-owned and operated business. In 2019, we rebranded as Eden Waste Solutions Pty Ltd to better reflect our expanding scope and commitment to sustainable waste management.</p>
                <p>We continue to take full responsibility and liability for maintaining compliance with WA government legislative requirements, ensuring that our operations align with the highest standards of environmental protection.</p>
                 </div>
            </div>
        </div>
    </div>

        
    <div class="row">
        <div class="col-md-12">
            <br>
            <div class="about-description-container">
                <div class="section-title">Why Choose Us?</div>
                <div class="about-description">
                <p>Eden Waste Solutions Pty Ltd is dedicated to providing the best waste disposal options across Western Australia. Our experience, innovative solutions, and commitment to sustainability make us the preferred partner for businesses looking to manage their waste effectively and responsibly.</p>
                 </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'about-component',
    data() {
        return {}
    },
    props: {
        msg: String
    },
    components: {

    }
}
</script>

<style lang="scss">
@import "../scss/common.scss";

.about-component {

    .row {

        .col-md-8, .col-md-12 {

            .about-description-container {
                @media only screen and (max-width: $break-medium) {
                    margin-bottom: 40px;
                }

              

                .about-description {

    font-size: $font-size-medium;
    color: $main-gray;
                    p {
                        color: $main-gray;
                    }
                }
            }
        }

        .col-md-4 {
            position: relative;

            .about-banner-container {
                position: relative;
                height: 100%;
                min-height: 300px;

                .about-banner-wrapper {
                    background: $main-green;
                    position: relative;
                    height: 100%;
                    border-radius: 30px;

                    .about-image {

                        position: relative;
                        height: 100%;
                        border-radius: 30px;
                        background-image: url('@/assets/slider-images/1.webp');
                        background-size: cover;
                        z-index: 0;
                        box-sizing: border-box;

                        .overlay {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(38deg, rgba(5, 63, 32) 0%, rgba(4, 43, 22, 0) 100%);
                            border-radius: 30px;
                            z-index: 1;
                        }

                        .about-state {
                            position: absolute;
                            width: 100%;
                            height: fit-content;
                            z-index: 2;
                            font-size: $font-size-xxlarge;
                            font-weight: bold;
                            color: #ffffff;
                            padding: 20px;
                            bottom: 20px;
                            font-family: $font-family-semibold;
                            // letter-spacing:0px;
                        }
                    }
                }
            }
        }
    }
}
</style>
