<template>
<div class='slider-component'>
    <div class="slider-component__slider-set">
        <div class="__slider-item" v-for="(sliderItem,index) in this.sliderItemSet" v-bind:key="index">
            <Transition>
                <div v-if="currentImage==sliderItem.image">
                    <div class="__slider-text">
                        <div class="__slider-text-container">
                            <div class="__slider_main-text">{{ this.mainText }}</div>
                            <div class="__slider-sub-text">{{ this.subText}}</div>
                        </div>
                    </div>
                    <div class="__slider-overlay"></div>

                    <div class="__slider-image" :style="{ backgroundImage: 'url(' + require('@/assets/slider-images/'+currentImage+'.webp') + ')' }"> </div>
                </div>
            </Transition>
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: 'slider-component',
    data() {
        return {
            backgroundImage: '1',
            sliderItemSet: [{
                image: 1,
                mainText: 'Skip Bins & Front lift Bins',
                subText: 'Skip Bins : 10 Cubic meter to 4 Cubic meter  special project and construction yards. Front Lift Bins – General waste & recycle (Cardboard)'
            }, {
                image: 2,
                mainText: 'Hydro Carbon waste',
                subText: 'Industrial waste from Hydro carbon contaminated wash waters rags from  cleaning process'
            }, {
                image: 3,
                mainText: 'Hazardous Waste',
                subText: 'DG rated waste treatment & disposal, also specialised in lab chemical temporary storage, segregation and reinstate when renovation completed (Schools and Business)'
            }, {
                image: 4,
                mainText: 'Industrial Cleaning',
                subText: 'High pressure cleaning and decontamination of small business (Including chemical spills  etc.)'
            }],
            currentImage: 1,
            mainText: 'ONE-STOP SHOP EQUIPPED WITH ENVIRONMENT FRIENDLY SOLUTIONS TO DEAL WITH WASTE MANAGEMENT',
            subText: 'Embracing sustainability is the right move towards reducing the cost of waste to your business and the environment.'
        }
    },
    props: {
        msg: String
    },
    components: {

    },
    methods() {

    },
    created() {
        let self = this;
        self.currentImage = self.sliderItemSet[0].image,
            self.mainText = self.sliderItemSet[0].mainText,
            self.subText = self.sliderItemSet[0].subText
        let sliderCount = 0;
        setInterval(() => {
            self.currentImage = self.sliderItemSet[sliderCount].image,
                self.mainText = self.sliderItemSet[sliderCount].mainText,
                self.subText = self.sliderItemSet[sliderCount].subText
            sliderCount++
            if (sliderCount == self.sliderItemSet.length) {
                sliderCount = 0
            }
            // console.info(self.currentImage);
        }, 10000);
    }
}
</script>

<style lang="scss">
@import "../scss/common.scss";

.slider-component {
    height: 600px;
    overflow: hidden;
    position: relative;

    .slider-component__slider-set {
        position: relative;
        width: 100%;
        height: 100%;

        .__slider-item {

            position: absolute;
            width: 100%;
            height: 100%;

            &.active {}

            .__slider-text {

                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 2;
                padding: 60px;

                @media only screen and (max-width: $break-medium) {
                    padding: 20px;
                }

                .__slider-text-container {
                    text-align: left;
                    max-width: 40%;
                    height: fit-content;
                    color: #ffffff;
                    position: absolute;
                    bottom: 60px;
                    box-sizing: border-box;

                    @media only screen and (max-width: $break-medium) {
                        width: calc(100% - 40px);
                        max-width: initial;
                    }

                    .__slider_main-text {
                        font-size: 36px;
                        font-weight: bolder;
                        font-family: 'Milliard ExtraBold';
                        letter-spacing: 1px;

                        @media only screen and (max-width: $break-medium) {
                            line-height: 40px;
                        }

                    }

                    .__slider-sub-text {
                        font-size: 20px;

                    }
                }
            }

            .__slider-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: linear-gradient(38deg, rgba(5, 63, 32) 0%, rgba(4, 43, 22, 0) 100%);
            }

            .__slider-image {
                position: absolute;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                z-index: 0;
            }
        }
    }
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

@include fade-opacity-transition(.2s);
</style>
