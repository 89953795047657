<template>
<div class='clients-component container component'>

    <div class="section-title">General waste collection</div>
    <div class="section-description">
        <p>We service the whole Perth Metro area and further if required. We take the full responsibility and liability of maintaining the WA government legislative requirements imposed by Department of Environment. We negotiate on behalf of you with our service providers to offer the best solution of waste disposal option in a competitive rates without compromising the safety ,reliability and sustainability which are our core values. We also have our contracted council approved waste transfer station, ensuring all waste is sorted accordingly and disposed of in the most environmentally friendly manner.</p>
        <p>We provide quality rubbish removal and recycling services for areas North and South of the river in Perth. We can handle both Residential and Commercial waste disposal for all projects big and small. We offer affordable rates and service all areas including the ones listed below.</p>
        <ul>
            <li>Canning Vale</li>
            <li>
                Canning Vale</li>
            <li>Fremantle</li>
            <li>Joondalup</li>
            <li>Kwinana

            </li>
            <li>Malaga</li>
            <li>Mandurah

            </li>
            <li>Midland</li>
            <li>Northern Suburbs</li>
            <li>Craigie</li>
            <li>Currambine</li>
            <li>Duncraig</li>
            <li>Edgewater</li>
            <li>Wanneroo</li>
        </ul>
    </div>
    <!-- <div class="clients-set">
        <div class="client-item">
            <img src="../assets/images/clients/bondall.png" alt="">
        </div>
        <div class="client-item">
            <img src="../assets/images/clients/city.png" alt="">
        </div>
        <div class="client-item">
            <img src="../assets/images/clients/christ.png" alt="">
        </div>
        <div class="client-item">
            <img src="../assets/images/clients/water.png" alt="">
        </div>
        <div class="client-item">
            <img src="../assets/images/clients/able.png" alt="">
        </div>
    </div> -->
</div>
</template>

<script>
export default {
    name: 'clients-component',
    data() {
        return {}
    },
    props: {
        msg: String
    },
    components: {

    }
}
</script>

<style lang="scss">
@import "../scss/common.scss";

.clients-component {
    flex-grow: 1;

    .section-description {
        font-size: $font-size-medium;
        color: $main-gray;

        ul {
            columns: 5;
            -webkit-columns: 5;
            -moz-columns: 5;

            @media only screen and (max-width: $break-medium) {
                columns: 3;
                -webkit-columns: 3;
                -moz-columns: 3;
            }

            @media only screen and (max-width: $break-small) {
                columns: 2;
                -webkit-columns: 2;
                -moz-columns: 2;
            }
        }
    }

    .clients-set {
        display: flex;
        justify-content: center;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .client-item {

            display: flex;
            flex: 1 0 20%;
            justify-content: center;

            @media only screen and (max-width: $break-medium) {

                flex: 1 0 33.33%;
            }

            @media only screen and (max-width: $break-small) {

                flex: 1 0 50%;
            }

            img {
                width: 100%;
                max-width: 150px;
                text-align: center;
                margin: auto;
            }
        }
    }
}
</style>
